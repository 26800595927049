<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import store from "@/store";

import { FilterType } from "@/common/types/filter";
import { CrewListType } from "@/common/types/crew";

import { CrewFilter, ListCrew, WebHeader, WebFooter } from "@/components";
import { svgIcon } from "@/common/components";

import { website } from "@/common/settings/website";

const { t } = useI18n();

const MOCK = [
  {
    crew_id: "151asd5as4da5sd4a",
    avatar: "https://images.media.io/pixpic-styles/Female_LinkedinPortrait.png",
    name: "José *****",
    blocked: true,
    availability: "IMMEDIATELY",
    state: "Rio de Janeiro",
    location: "BR",
  },
  {
    crew_id: "151asd5as4da5scab",
    avatar: "https://images.media.io/pixpic-styles/Male_LinkedinPortrait.png",
    name: "Mariana *****",
    blocked: true,
    availability: "WEEKS_1_2",
    state: "São Paulo",
    location: "BR",
  },
];

const filter = ref<FilterType | null>(null);
const listData = ref<CrewListType[]>([]);

const total = ref(0);

const filtered = ref(false);

const menuList = computed(() =>
  website.menu.map((item) => ({
    ...item,
    label: t(item.label),
  }))
);

function fetchData() {
  listData.value = [...MOCK];
}

function scrollToTop() {
  window.scrollTo({ top: 0, behavior: "smooth" });
}

function applyFilter(data: FilterType) {
  filter.value = data;
  filtered.value = true;
  fetchData();
  scrollToTop();
}

function clearFilter(data: FilterType) {
  filter.value = data;
  filtered.value = false;
}
</script>

<template>
  <article class="crew-page">
    <web-header :menu-list="menuList" menu-active="find_crew" />

    <section class="header">
      <div class="title">
        <div class="icon">
          <svg-icon icon="rudder" size="2.5rem" />
        </div>
        <span>
          {{ t("crew.labels.crew") }}
        </span>
      </div>
      <div class="actions">
        <a href="/auth/login" role="button" class="btn btn-black h-10 px-8">
          {{ t("crew.labels.register_cv") }}
        </a>
      </div>
    </section>

    <section class="content">
      <div class="filter">
        <crew-filter
          :data="filter"
          :show-advanced-filters="filtered"
          @filter:apply="applyFilter($event)"
          @filter:clear="clearFilter($event)"
        />
      </div>
      <div class="list">
        <template v-if="filtered && listData.length > 0">
          <list-crew :list="listData" :total="total" />
        </template>

        <template v-if="filtered && listData.length === 0">
          <div class="empty-list">
            {{ t("crew.texts.empty_list") }}
          </div>
        </template>
      </div>
    </section>

    <web-footer
      menu-active="find_crew"
      :menu-list="menuList"
      :media-list="website.social_media"
      :copyright-year="website.copyright.year"
      :copyright-title="website.copyright.name"
    />
  </article>
</template>

<style lang="scss" scoped>
.crew-page {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 5rem;
    padding-inline: 4%;

    .title {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .icon {
        color: var(--secondary);
      }

      span {
        font-size: 1.5rem;
        text-transform: uppercase;
      }
    }

    .actions {
      display: flex;
      align-items: center;

      a {
        text-decoration: none;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    gap: 1.54rem;
    background: #ffffff;
    padding-block: 1rem;
    padding-inline: 4%;

    .filter {
      padding-block-end: 2rem;
    }

    .list {
      flex: 1;

      .empty-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-block: 10%;
      }
    }
  }
}
</style>
