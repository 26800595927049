<script setup lang="ts">
import { ref, watchEffect } from "vue";
import { svgIcon } from "@/common/components";

const props = withDefaults(
  defineProps<{
    name?: string;
    role: string;
    width?: string;
    open?: boolean;
    fontSize?: string;
    size?: string;
  }>(),
  {
    width: "11rem",
    size: "2.5rem",
    fontSize: "0.875rem",
  }
);

const showMenu = ref(false);

watchEffect(() => {
  showMenu.value = props.open;
});
</script>

<template>
  <section class="base-avatar-user">
    <div class="avatar">
      <svg-icon icon="person" size="1.5rem" />
    </div>
    <div class="infos">
      <template v-if="name">
        <div class="name ellipsis">
          {{ name }}
        </div>
      </template>
      <template v-if="role">
        <div class="type ellipsis">
          {{ role }}
        </div>
      </template>
    </div>
    <div class="arrow">
      <template v-if="showMenu">
        <svg-icon icon="arrow-up" size="1rem" />
      </template>
      <template v-else>
        <svg-icon icon="arrow-down" size="1rem" />
      </template>
    </div>
  </section>
</template>

<style lang="scss" scoped>
$size: v-bind("size");
$width: v-bind("width");
$font-size: v-bind("fontSize");

.base-avatar-user {
  user-select: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  width: $width;
  height: min-content;
  align-items: center;
  padding-block: 0.25rem;
  background: var(--white-off);
  border-radius: 0.75rem;
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;

  .avatar {
    display: flex;
    width: $size;
    height: $size;
    min-width: $size;
    align-items: center;
    justify-content: center;
    background: #d3d3d3;
    border-radius: 50%;
    margin-block: auto;
    margin-inline-start: 0.3rem;
    margin-inline-end: 0.7rem;
    color: var(--white);
  }

  .infos {
    flex: 1;

    .name {
      width: 100%;
      font-size: $font-size;
      font-weight: bold;
    }

    .type {
      font-size: calc($font-size * 0.9);
    }
  }

  .arrow {
    padding-inline: 0.5rem;
    color: var(--text-off);
  }
}
</style>
