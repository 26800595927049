export const crew = {
    labels: {
        advanced_filters: "Filtros Avançados",
        availability: "Disponibilidade",
        candidates: "Candidatos",
        crew: "Crew Member",
        find_crew: "Tripulação",
        experience: "Experiência",
        location: "Localização",
        jobs: "Vagas",
        position: "Posição",
        register_cv: "Cadastrar CV",
        register_vacancy: "Cadastrar Vaga",
        clear_filter: "Limpar Filtros",
        apply_filter: "Aplicar Filtros",
        view_profile: "Ver perfil",
    },
    placeholders: {
        availability: "Quão breve?",
        experience: "Qual experiência?",
        location: "Onde?",
        position: "Qual posição você procura?",
    },
    texts: {
        crew_filter_title: "Preencha os filtros e confira os profissionais disponíveis em nossa plataforma.",
        empty_list: "Não foi possível encontrar candidatos para a sua busca!",
    },
};
