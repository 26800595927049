<script setup lang="ts">
import store from "@/store";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { baseHeader, sideMenu, fullLoading } from "@/common/components";

const { t } = useI18n();

const menuList = computed(() => [
  {
    icon: "change",
    label: t("common.labels.change_account"),
    url: "/accounts",
    active: true,
  },
  {
    icon: "plans",
    label: t("common.labels.plans"),
    url: "/plans",
    active: true,
  },
]);
</script>

<template>
  <section class="screen">
    <side-menu :menus="menuList" />
    <section class="pages">
      <base-header background="#1C4736" />
      <main class="main">
        <slot name="main"></slot>
      </main>
    </section>
    <full-loading v-if="store.getters.loading" />
  </section>
</template>

<style lang="scss" scoped>
.screen {
  display: grid;
  width: 100%;
  height: 100vh;
  grid-template-columns: auto 1fr;

  .pages {
    display: flex;
    flex-direction: column;

    main {
      display: flex;
      flex-direction: column;
      padding-block-start: 1.57rem;
      padding-inline: 1.57rem;
      height: calc(100vh - 4rem - 1.57rem);
      overflow-y: auto;
    }
  }
}
</style>