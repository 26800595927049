<script setup lang="ts">
import store from "@/store";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { MD5 } from "crypto-js";
import { toast } from "@/common/utils/toast";
import { baseInput, svgIcon } from "@/common/components";

import { setUser } from "@/common/services/user";
import { userLogin, userProfile, userAccount } from "@/common/api/rest/user";
import type { UserLoginType } from "@/common/types/user";

const router = useRouter();
const { t } = useI18n();

const payload = ref<UserLoginType>({
  login: "",
  password: "",
});

const passwordShow = ref(false);
const error = ref<{ [key: string]: string }>({});

function goTo(url: string) {
  router.push(url);
}

function isValid() {
  const { login, password } = payload.value;

  error.value = {};

  if (login.trim().length === 0) error.value.login = t("error.required");

  if (password.trim().length === 0) error.value.password = t("error.required");

  return Object.keys(error.value).length === 0;
}

function fetchAccount(member_id: string) {
  userAccount(member_id).then((response) => {
    const { success, data } = response;
    if (success) {
      store.commit("SET_ACCOUNT", data.owner);
      goTo("/home");
    } else {
      toast({ message: data.message, type: "error" });
    }
  });
}

function fecthProfile(member_id: string) {
  userProfile(member_id).then((response) => {
    const { success, data } = response;
    if (success) {
      store.commit("SET_PROFILE", data);
      fetchAccount(member_id);
    } else {
      toast({ message: data.message, type: "error" });
    }
  });
}

function sendLogin() {
  if (!isValid()) return;

  userLogin({
    ...payload.value,
    password: MD5(payload.value.password).toString(),
  })
    .then((response) => {
      const { success, data } = response;
      if (success) {
        setUser(data)
          .then(() => {
            fecthProfile(data.member_id);
          })
          .catch(() => {
            toast({ message: t("error.default"), type: "error" });
          });
      } else {
        toast({ message: data.message, type: "error" });
      }
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    });
}

onMounted(() => {
  if (store.getters.logged) goTo("/home");
});
</script>

<template>
  <main class="screen">
    <section class="box-login">
      <div class="left">
        <img :src="require('@/assets/images/logo.png')" />
      </div>

      <div class="right">
        <h2>
          {{ t("common.labels.login").toUpperCase() }}
        </h2>
        <form @submit.prevent="sendLogin()">
          <base-input
            v-model="payload.login"
            :placeholder="t('common.placeholders.login')"
            background="#f1f1f1"
            maxLength="50"
          >
            <template #label>
              {{ t("common.labels.login") }}
            </template>
            <template #sufix>
              <svg-icon icon="person" size="1.5rem" />
            </template>
            <template v-if="error.login" #error>
              {{ error.login }}
            </template>
          </base-input>

          <base-input
            v-model="payload.password"
            :placeholder="t('common.placeholders.password')"
            :type="passwordShow ? 'text' : 'password'"
            background="#f1f1f1"
            maxLength="50"
          >
            <template #label>
              {{ t("common.labels.password") }}
            </template>
            <template #sufix>
              <div
                v-if="passwordShow"
                class="pointer"
                @click="passwordShow = false"
              >
                <svg-icon icon="eye" size="1.5rem" />
              </div>
              <div v-else class="pointer" @click="passwordShow = true">
                <svg-icon icon="eye-closed" size="1.5rem" />
              </div>
            </template>
            <template v-if="error.password" #error>
              {{ error.password }}
            </template>
          </base-input>

          <div class="actions">
            <button class="btn btn-primary">
              {{ t("common.labels.enter")?.toUpperCase() }}
            </button>

            <div class="recover">
              <button
                type="button"
                class="btn btn-none font-bold color-text-medium"
                @click="goTo('/auth/recover')"
              >
                {{ t("common.texts.recover_password") }}!
              </button>
            </div>

            <div class="register">
              <span>
                {{ t("common.texts.dont_account") }}
              </span>
              <button
                type="button"
                class="btn btn-none font-bold"
                @click="goTo('/auth/register')"
              >
                {{ t("common.texts.register_account") }}!
              </button>
              <div></div>
            </div>
          </div>
        </form>
      </div>
    </section>
  </main>
</template>

<style lang="scss" scoped>
.screen {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: var(--black-background);
  background-image: url("@/assets/images/sea-background.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .box-login {
    display: flex;
    flex-direction: row;
    width: 750px;
    height: 500px;
    background: var(--white);
    border-radius: 0.75rem;

    .left {
      display: flex;
      width: 50%;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 220px;
      }
    }

    .right {
      display: flex;
      width: 50%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      padding-inline: 8%;

      form {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 0.875rem;
      }

      .actions {
        display: flex;
        width: 100%;
        flex-direction: column;
        margin-block-start: 1rem;
        gap: 1rem;

        .recover {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          margin-block-start: 0.5rem;
        }

        .register {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          margin-block-start: 0.5rem;

          span {
            font-size: 0.875rem;
            width: fit-content;
            text-align: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .screen {
    .box-login {
      flex-direction: column;
      width: 90%;
      height: auto;
      padding-block: 1.5rem;

      .left {
        width: 100%;
        padding-block: 1.5rem;
      }

      .right {
        width: 84%;
      }
    }
  }
}
</style>