import { common } from "./common";
import { crew } from "./crew";
import { enums } from "./enums";
import { error } from "./error";
import { home } from "./home";
import { plan } from "./plan";
import { user } from "./user";

export const language = {
    translations: {
        common,
        crew,
        enums,
        error,
        home,
        plan,
        user,
    },
};