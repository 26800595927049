<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { CrewListType } from "@/common/types/crew";

import { svgIcon } from "@/common/components";

const { t, tm } = useI18n();

defineProps<{
  data: CrewListType;
}>();

const countries = computed(() => tm("enums.countries"));
</script>

<template>
  <article class="card-crew">
    <section class="avatar">
      <div class="image">
        <template v-if="data.avatar">
          <img :src="data.avatar" alt="avatar" />
        </template>
        <template v-else>
          <svg-icon icon="no-image" size="1.5rem" />
        </template>
      </div>
    </section>

    <section class="name">
      <span class="ellipsis">
        {{ data.name }}
      </span>
      <template v-if="data.blocked">
        <div class="blocked">
          <svg-icon icon="lock" size="1rem" />
        </div>
      </template>
    </section>
    <section>
      {{ data.availability }}
    </section>
    <section class="location">
      <span>
        {{ data.state }}
      </span>
      <span>
        {{ countries[data.location] }}
      </span>
    </section>

    <section class="actions">
      <button type="button" class="btn btn-sm btn-primary">
        {{ t("crew.labels.view_profile") }}
      </button>
    </section>
  </article>
</template>

<style lang="scss" scoped>
.card-crew {
  display: grid;
  grid-template-columns: 6.5rem 1fr 1fr 1fr 7rem;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;

  .avatar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 6rem;
    height: 6rem;

    .image {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 90%;
      height: 90%;
      border-radius: 0.25rem;
      background: var(--white-off);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.25rem;
      }
    }
  }

  .name {
    display: grid;
    grid-template-columns: auto 1rem;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
  }

  .location {
    display: flex;
    flex-direction: column;
  }

  .blocked {
    width: 1rem;

    :hover {
      color: red;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      font-size: 0.75rem;
      text-transform: uppercase;
      padding-block: 0.35rem;
      padding-inline: 0.75rem;
    }
  }
}
</style>