<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";

import { CrewListType } from "@/common/types/crew";

import { CardCrew } from "@/components";
import { svgIcon } from "@/common/components";

const { t } = useI18n();

const props = defineProps<{
  list: CrewListType[];
  total: number;
}>();
</script>

<template>
  <article class="list-crew">
    <section class="header">
      <div class="total">
        {{ props.total || 0 }} {{ t("crew.labels.candidates") }}
      </div>
      <div class="labels">
        <label></label>
        <label>{{ t("common.labels.name") }}</label>
        <label>{{ t("crew.labels.availability") }}</label>
        <label>{{ t("crew.labels.location") }}</label>
        <label>{{ t("common.labels.actions") }}</label>
      </div>
    </section>

    <section class="list">
      <template v-for="item in props.list" :key="item.crew_id">
        <card-crew :data="item" />
        <div class="separator" />
      </template>
    </section>
  </article>
</template>

<style lang="scss" scoped>
.list-crew {
  display: flex;
  flex-direction: column;
  width: inherit;
  gap: 1rem;

  .header {
    display: flex;
    flex-direction: column;
    width: inherit;
    gap: 0.5rem;

    .total {
      font-size: 1.15rem;
      font-weight: bold;
      padding-inline: 1.75rem;
    }

    .labels {
      display: grid;
      grid-template-columns: 6.5rem 1fr 1fr 1fr 6rem;
      gap: 0.5rem;
      background: var(--white-off);
      padding-block: 0.75rem;
      padding-inline: 1.75rem;
      font-size: 0.875rem;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    padding-inline: 1.75rem;
  }

  .separator {
    height: 1px;
    background: var(--white-off);
    margin-block: 0.5rem;
  }
}
</style>