<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import store from "@/store";

import { FilterType } from "@/common/types/filter";
import { CrewListType } from "@/common/types/crew";

import { PanelTheme } from "@/common/themes";
import { CrewFilter, ListCrew } from "@/components";
import { svgIcon } from "@/common/components";

const { t } = useI18n();

const MOCK = [
  {
    crew_id: "151asd5as4da5sd4a",
    avatar: "https://images.media.io/pixpic-styles/Female_LinkedinPortrait.png",
    name: "José *****",
    blocked: true,
    availability: "IMMEDIATELY",
    state: "Rio de Janeiro",
    location: "BR",
  },
  {
    crew_id: "151asd5as4da5scab",
    avatar: "https://images.media.io/pixpic-styles/Male_LinkedinPortrait.png",
    name: "Mariana *****",
    blocked: true,
    availability: "WEEKS_1_2",
    state: "São Paulo",
    location: "BR",
  },
];

const filter = ref<FilterType | null>(null);
const listData = ref<CrewListType[]>([]);

const total = ref(0);

const filtered = ref(false);

function fetchData() {
  listData.value = [...MOCK];
}

function scrollToTop() {
  const mainElement = document.querySelector(".main");
  mainElement.scrollTo({ top: 0, behavior: "smooth" });
}

function applyFilter(data: FilterType) {
  filter.value = data;
  filtered.value = true;
  fetchData();
  scrollToTop();
  console.log("applyFilter", data);
}

function clearFilter(data: FilterType) {
  filter.value = data;
  filtered.value = false;
  console.log("clearFilter", data);
}
</script>

<template>
  <panel-theme>
    <article class="crew-page">
      <section class="header">
        <div class="title">
          <div class="icon">
            <svg-icon icon="rudder" size="2.5rem" />
          </div>
          <span>
            {{ t("crew.labels.crew") }}
          </span>
        </div>
        <div class="actions">
          <button type="button" class="btn btn-black h-10 px-8">
            {{ t("crew.labels.register_cv") }}
          </button>
        </div>
      </section>
      <section class="content">
        <div class="filter">
          <crew-filter
            :data="filter"
            :show-advanced-filters="filtered"
            @filter:apply="applyFilter($event)"
            @filter:clear="clearFilter($event)"
          />
        </div>
        <div class="list">
          <template v-if="filtered && listData.length > 0">
            <list-crew :list="listData" :total="total" />
          </template>

          <template v-if="filtered && listData.length === 0">
            <div class="empty-list">
              {{ t("crew.texts.empty_list") }}
            </div>
          </template>
        </div>
      </section>
    </article>
  </panel-theme>
</template>

<style lang="scss" scoped>
.crew-page {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 3rem;
    padding-block-end: 20px;
    padding-inline: 1rem;

    .title {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .icon {
        color: var(--secondary);
      }

      span {
        font-size: 1.5rem;
        text-transform: uppercase;
      }
    }

    .actions {
      display: flex;
      align-items: center;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    gap: 1.54rem;
    background: #ffffff;
    padding: 1rem;
    border-radius: 0.5rem;

    .filter {
      padding-block-end: 2rem;
    }

    .list {
      flex: 1;

      .empty-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-block: 10%;
      }
    }
  }
}
</style>
