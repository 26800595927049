<script setup lang="ts">
import { computed, ref } from "vue";
import { svgIcon } from "@/common/components";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { removeUser } from "@/common/services/user";

const { t, tm } = useI18n();
const router = useRouter();

withDefaults(
  defineProps<{
    width?: string;
    height?: string;
    background?: string;
  }>(),
  {
    width: "15rem",
    height: "4rem",
    background: "#fff",
  }
);

const showMenu = ref(false);
const showMessages = ref(false);

const types = computed(() => tm("technical.types") as Record<string, string>);

function goTo(url: string) {
  showMenu.value = false;
  router.push(url);
}

function logout() {
  removeUser();
}
</script>

<template>
  <header class="base-header">
    <section class="brand">
      <img :src="require('@/assets/images/logo-secondary-white.png')" />
    </section>

    <section class="actions">
      <template v-if="showMessages">
        <div class="box-messages">messages</div>
        {{ types }}
      </template>
    </section>

    <section class="user">
      <div class="content" @click="showMenu = !showMenu">
        <div class="avatar">
          <svg-icon icon="person" size="1.5rem" />
        </div>
        <div class="infos">
          <template v-if="$store.getters.profile?.first_name">
            <div class="name ellipsis">
              {{ $store.getters.profile?.first_name }}
            </div>
          </template>
          <template v-if="$store.getters.type">
            <div class="type ellipsis">
              {{ types[$store.getters.type] ?? "" }}
            </div>
          </template>
        </div>
        <div class="arrow">
          <template v-if="showMenu">
            <svg-icon icon="arrow-up" size="1rem" />
          </template>
          <template v-else>
            <svg-icon icon="arrow-down" size="1rem" />
          </template>
        </div>
      </div>

      <template v-if="showMenu">
        <div class="box-menu">
          <div class="menu-content">
            <div class="item" @click="goTo('/accounts')">
              <svg-icon icon="change" size="1.75rem" />
              {{ t("common.labels.change_account") }}
            </div>
            <div class="item" @click="goTo('/profile')">
              <svg-icon icon="person" size="1.75rem" />
              {{ t("common.labels.my_profile") }}
            </div>
            <div class="item" @click="logout()">
              <svg-icon icon="exit" size="1.75rem" />
              {{ t("common.labels.exit") }}
            </div>
          </div>
        </div>
      </template>
    </section>
  </header>
</template>


<style lang="scss" scoped>
$width: v-bind("width");
$height: v-bind("height");
$background: v-bind("background");

.base-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  height: $height;
  background: $background;
  box-shadow: 3px 2px 5px var(--shadow);
  z-index: 2;

  .brand {
    display: flex;
    flex-direction: column;
    height: $height;
    justify-content: center;
    border-inline-start: 2px solid #e4e4e4;
    padding-inline-start: 1.57rem;

    img {
      width: min-content;
      height: 40px;
    }
  }

  .actions {
    position: relative;
    display: flex;
    padding-block: 0.75rem;
    align-items: center;
    justify-content: center;

    .content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      max-width: 12rem;
      background: var(--white-off);
      border-radius: 0.75rem;
      gap: 0.75rem;

      .item {
        user-select: none;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: top;
        color: var(--text-off);
      }
    }
  }

  .user {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-inline-end: 1.57rem;

    .content {
      user-select: none;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      width: $width;
      height: min-content;
      align-items: center;
      padding-block: 0.25rem;
      background: var(--white-off);
      border-radius: 0.75rem;
      border-top-left-radius: 2rem;
      border-bottom-left-radius: 2rem;

      .avatar {
        display: flex;
        width: 2.5rem;
        height: 2.5rem;
        min-width: 2.5rem;
        align-items: center;
        justify-content: center;
        background: #d3d3d3;
        border-radius: 50%;
        margin-block: auto;
        margin-inline-start: 0.3rem;
        margin-inline-end: 0.7rem;
        color: var(--white);
      }

      .infos {
        flex: 1;

        .name {
          width: 100%;
          font-size: 0.875rem;
          font-weight: bold;
        }

        .type {
          font-size: 0.8rem;
        }
      }

      .arrow {
        padding-inline: 0.5rem;
        color: var(--text-off);
      }
    }
  }

  .box-messages {
    position: absolute;
    top: calc($height + 0.5rem);
    width: 100%;
    height: 20rem;
    background: var(--white);
    box-shadow: -3px 2px 5px var(--shadow);
    border: 1px solid var(--border);
    border-radius: 0.3rem;
    z-index: 0;
  }

  .box-menu {
    position: absolute;
    top: calc($height + 0.5rem);
    width: $width;
    background: var(--white);
    box-shadow: -3px 2px 5px var(--shadow);
    border: 1px solid var(--border);
    border-radius: 0.3rem;
    z-index: 0;

    .menu-content {
      display: flex;
      flex-direction: column;
      padding-block: 0.5rem;
      gap: 0.5rem;

      .item {
        user-select: none;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.75rem;
        padding-inline: 0.75rem;
        padding-block: 0.5rem;
        color: var(--text);

        &:hover {
          background: var(--primary);
          color: var(--primary-color);
        }
      }
    }
  }
}
</style>