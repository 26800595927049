import {
    AdvancedFilterEnum,
    AvailabilityEnum,
    CountryEnum,
    ExperienceEnum,
    GenderEnum,
} from "@/common/types/enum";

export const enums = {
    advanced_filter: {
        [AdvancedFilterEnum.MALE]: "Masculino",
        [AdvancedFilterEnum.FEMALE]: "Feminino",
        [AdvancedFilterEnum.TEAM_COUPLE_ONLY]: "Apenas Equipe/Casal",
        [AdvancedFilterEnum.SAILING]: "Náutica",
        [AdvancedFilterEnum.MOTOR]: "Motor",
        [AdvancedFilterEnum.COMMERCIAL]: "Comercial",
        [AdvancedFilterEnum.CHARTER]: "Charter",
        [AdvancedFilterEnum.B1_B2_VISA]: "Visto B1/B2",
        [AdvancedFilterEnum.C1_D_VISA]: "Visto C1/D",
        [AdvancedFilterEnum.GREEN_CARD_US_CITIZEN]: "Green Card / Cidadão dos EUA",
        [AdvancedFilterEnum.SCHENGEN_EU_RESIDENT]: "Schengen / Residente da UE",
        [AdvancedFilterEnum.HOSPITALITY_EXPERIENCE]: "Experiência em Hospitalidade",
        [AdvancedFilterEnum.MICHELIN_EXPERIENCE]: "Experiência Michelin",
        [AdvancedFilterEnum.INTRODUCTION_VIDEO]: "Vídeo de Apresentação",
        [AdvancedFilterEnum.VERIFIED_REFERENCE]: "Referência Verificada",
        [AdvancedFilterEnum.BACKGROUND_CHECK]: "Verificação de Antecedentes",
        [AdvancedFilterEnum.SEA_SERVICE_PROVIDED]: "Serviço de Mar Fornecido"
    },
    availability: {
        [AvailabilityEnum.IMMEDIATELY]: "Imediatamente",
        [AvailabilityEnum.WITHIN_1_WEEK]: "Dentro de 1 semana",
        [AvailabilityEnum.WEEKS_1_2]: "1-2 semanas",
        [AvailabilityEnum.WEEKS_2_2]: "2-2 semanas",
        [AvailabilityEnum.WEEKS_4_MORE]: "4+ semanas",
        [AvailabilityEnum.UNKNOWN]: "Desconhecido",
        [AvailabilityEnum.UNAVAILABLE]: "Indisponível",
    },
    countries: {
        [CountryEnum.AFGHANISTAN]: "Afeganistão",
        [CountryEnum.SOUTH_AFRICA]: "África do Sul",
        [CountryEnum.GERMANY]: "Alemanha",
        [CountryEnum.ARGENTINA]: "Argentina",
        [CountryEnum.AUSTRALIA]: "Austrália",
        [CountryEnum.BELGIUM]: "Bélgica",
        [CountryEnum.BRAZIL]: "Brasil",
        [CountryEnum.CANADA]: "Canadá",
        [CountryEnum.CHILE]: "Chile",
        [CountryEnum.CHINA]: "China",
        [CountryEnum.COLOMBIA]: "Colômbia",
        [CountryEnum.SOUTH_KOREA]: "Coreia do Sul",
        [CountryEnum.DENMARK]: "Dinamarca",
        [CountryEnum.EGYPT]: "Egito",
        [CountryEnum.UNITED_ARAB_EMIRATES]: "Emirados Árabes Unidos",
        [CountryEnum.SPAIN]: "Espanha",
        [CountryEnum.UNITED_STATES]: "Estados Unidos",
        [CountryEnum.FRANCE]: "França",
        [CountryEnum.GREECE]: "Grécia",
        [CountryEnum.INDIA]: "Índia",
        [CountryEnum.INDONESIA]: "Indonésia",
        [CountryEnum.IRAN]: "Irã",
        [CountryEnum.ISRAEL]: "Israel",
        [CountryEnum.ITALY]: "Itália",
        [CountryEnum.JAPAN]: "Japão",
        [CountryEnum.MEXICO]: "México",
        [CountryEnum.NIGERIA]: "Nigéria",
        [CountryEnum.NORWAY]: "Noruega",
        [CountryEnum.NEW_ZEALAND]: "Nova Zelândia",
        [CountryEnum.NETHERLANDS]: "Países Baixos",
        [CountryEnum.PAKISTAN]: "Paquistão",
        [CountryEnum.PERU]: "Peru",
        [CountryEnum.POLAND]: "Polônia",
        [CountryEnum.PORTUGAL]: "Portugal",
        [CountryEnum.UNITED_KINGDOM]: "Reino Unido",
        [CountryEnum.RUSSIA]: "Rússia",
        [CountryEnum.SWEDEN]: "Suécia",
        [CountryEnum.SWITZERLAND]: "Suíça",
        [CountryEnum.THAILAND]: "Tailândia",
        [CountryEnum.TURKEY]: "Turquia",
        [CountryEnum.UKRAINE]: "Ucrânia",
        [CountryEnum.URUGUAY]: "Uruguai",
        [CountryEnum.VENEZUELA]: "Venezuela",
        [CountryEnum.VIETNAM]: "Vietnã",
        [CountryEnum.ZIMBABWE]: "Zimbábue",
    },
    experience: {
        [ExperienceEnum.MONTHS_0_6]: "0 - 6 meses",
        [ExperienceEnum.MONTHS_6_YEAR_1]: "6 meses - 1 ano",
        [ExperienceEnum.YEARS_1_2]: "1 - 2 anos",
        [ExperienceEnum.YEARS_2_5]: "2 - 5 anos",
        [ExperienceEnum.YEARS_5_MORE]: "5+ anos",
        [ExperienceEnum.YEARS_10_MORE]: "10+ anos",
    },
    gender: {
        [GenderEnum.MALE]: "Male",
        [GenderEnum.FEMALE]: "Feme",
        [GenderEnum.OTHER]: "Other",
    },
};
