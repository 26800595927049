export const home = {
    labels: {
        welcome: "Bem vindo",
        crew_member: "Crew Member",
        create_profile: "Criar Perfil",
        offer_vacancy: "Ofertar Vaga",
        offer_yacht: "Ofertar Iate",
        see_services: "Ver Serviços",
        services: "Serviços",
        vacancies: "Vagas",
        yacht_master: "Yacht Master",
    },
    texts: {
        crew_member: "Desenvolvemos este espaço exclusivo para facilitar e elevar a vida de Crew Members, novatos e veteranos, interessados em entrar e crescer na indústria dos super iates de luxo. Aqui você encontra oportunidades de trabalho, cursos profissionalizantes, conteúdos informativos e muito mais em apenas alguns cliques. Confira agora!",
        yacht_master: "Interessado em divulgar seu iate na Brazilis?<br>Basta criar um perfil, nos contar mais sobre sua embarcação e ativar seu anúncio. Em apenas alguns cliques você terá acesso à esta incrível oportunidade!",
        offer_vacancy: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    },
};
