export const crew = {
    labels: {
        advanced_filters: "Advanced Filters",
        availability: "Availability",
        crew: "Crew Member",
        experience: "Experience",
        location: "Location",
        position: "Position",
        register_cv: "Register CV",
    },
    placeholders: {
        availability: "How soon?",
        experience: "What experience?",
        location: "Where?",
        position: "What position are you looking for?",
    },
    texts: {
        crew_filter_title: "Fill in the filters and check out the professionals available on our platform.",
    },
};