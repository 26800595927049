<script setup lang="ts">
import { PanelTheme } from "@/common/themes";

import { baseSelect } from "@/common/components";

const options = [
  {
    option: "Teste",
    value: "teste",
  },
];
</script>

<template>
  <panel-theme>
    <section class="">
      <section>
        ACCOUNT
      </section>
      <section>
        <base-select :options="options">
        </base-select>
      </section>
    </section>
  </panel-theme>
</template>

<style lang="scss" scoped>
</style>
