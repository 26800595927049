import {
    AdvancedFilterEnum,
    AvailabilityEnum,
    CountryEnum,
    ExperienceEnum,
    GenderEnum,
} from "@/common/types/enum";

export const enums = {
    advanced_filter: {
        [AdvancedFilterEnum.MALE]: "Male",
        [AdvancedFilterEnum.FEMALE]: "Female",
        [AdvancedFilterEnum.TEAM_COUPLE_ONLY]: "Team/Couple Only",
        [AdvancedFilterEnum.SAILING]: "Sailing",
        [AdvancedFilterEnum.MOTOR]: "Motor",
        [AdvancedFilterEnum.COMMERCIAL]: "Commercial",
        [AdvancedFilterEnum.CHARTER]: "Charter",
        [AdvancedFilterEnum.B1_B2_VISA]: "B1/B2 Visa",
        [AdvancedFilterEnum.C1_D_VISA]: "C1/D Visa",
        [AdvancedFilterEnum.GREEN_CARD_US_CITIZEN]: "Green Card (or US Citizen)",
        [AdvancedFilterEnum.SCHENGEN_EU_RESIDENT]: "Schengen (or EU Resident)",
        [AdvancedFilterEnum.HOSPITALITY_EXPERIENCE]: "Hospitality Experience",
        [AdvancedFilterEnum.MICHELIN_EXPERIENCE]: "Michelin Experience",
        [AdvancedFilterEnum.INTRODUCTION_VIDEO]: "Introduction Video",
        [AdvancedFilterEnum.VERIFIED_REFERENCE]: "Verified Reference",
        [AdvancedFilterEnum.BACKGROUND_CHECK]: "Background Check",
        [AdvancedFilterEnum.SEA_SERVICE_PROVIDED]: "Sea Service Provided"
    },
    availability: {
        [AvailabilityEnum.IMMEDIATELY]: "Immediately",
        [AvailabilityEnum.WITHIN_1_WEEK]: "Within 1 week",
        [AvailabilityEnum.WEEKS_1_2]: "1-2 weeks",
        [AvailabilityEnum.WEEKS_2_2]: "2-2 weeks",
        [AvailabilityEnum.WEEKS_4_MORE]: "4+ weeks",
        [AvailabilityEnum.UNKNOWN]: "Unknown",
        [AvailabilityEnum.UNAVAILABLE]: "Unavailable",
    },
    countries: {
        [CountryEnum.AFGHANISTAN]: "Afghanistan",
        [CountryEnum.SOUTH_AFRICA]: "South Africa",
        [CountryEnum.GERMANY]: "Germany",
        [CountryEnum.ARGENTINA]: "Argentina",
        [CountryEnum.AUSTRALIA]: "Australia",
        [CountryEnum.BELGIUM]: "Belgium",
        [CountryEnum.BRAZIL]: "Brazil",
        [CountryEnum.CANADA]: "Canada",
        [CountryEnum.CHILE]: "Chile",
        [CountryEnum.CHINA]: "China",
        [CountryEnum.COLOMBIA]: "Colombia",
        [CountryEnum.SOUTH_KOREA]: "South Korea",
        [CountryEnum.DENMARK]: "Denmark",
        [CountryEnum.EGYPT]: "Egypt",
        [CountryEnum.UNITED_ARAB_EMIRATES]: "United Arab Emirates",
        [CountryEnum.SPAIN]: "Spain",
        [CountryEnum.UNITED_STATES]: "United States",
        [CountryEnum.FRANCE]: "France",
        [CountryEnum.GREECE]: "Greece",
        [CountryEnum.INDIA]: "India",
        [CountryEnum.INDONESIA]: "Indonesia",
        [CountryEnum.IRAN]: "Iran",
        [CountryEnum.ISRAEL]: "Israel",
        [CountryEnum.ITALY]: "Italy",
        [CountryEnum.JAPAN]: "Japan",
        [CountryEnum.MEXICO]: "Mexico",
        [CountryEnum.NIGERIA]: "Nigeria",
        [CountryEnum.NORWAY]: "Norway",
        [CountryEnum.NEW_ZEALAND]: "New Zealand",
        [CountryEnum.NETHERLANDS]: "Netherlands",
        [CountryEnum.PAKISTAN]: "Pakistan",
        [CountryEnum.PERU]: "Peru",
        [CountryEnum.POLAND]: "Poland",
        [CountryEnum.PORTUGAL]: "Portugal",
        [CountryEnum.UNITED_KINGDOM]: "United Kingdom",
        [CountryEnum.RUSSIA]: "Russia",
        [CountryEnum.SWEDEN]: "Sweden",
        [CountryEnum.SWITZERLAND]: "Switzerland",
        [CountryEnum.THAILAND]: "Thailand",
        [CountryEnum.TURKEY]: "Turkey",
        [CountryEnum.UKRAINE]: "Ukraine",
        [CountryEnum.URUGUAY]: "Uruguay",
        [CountryEnum.VENEZUELA]: "Venezuela",
        [CountryEnum.VIETNAM]: "Vietnam",
        [CountryEnum.ZIMBABWE]: "Zimbabwe",
    },
    experience: {
        [ExperienceEnum.MONTHS_0_6]: "0 - 6 months",
        [ExperienceEnum.MONTHS_6_YEAR_1]: "6 months - 1 year",
        [ExperienceEnum.YEARS_1_2]: "1 - 2 years",
        [ExperienceEnum.YEARS_2_5]: "2 - 5 years",
        [ExperienceEnum.YEARS_5_MORE]: "5+ years",
        [ExperienceEnum.YEARS_10_MORE]: "10+ years"
    },
    gender: {
        [GenderEnum.MALE]: "Masculino",
        [GenderEnum.FEMALE]: "Feminino",
        [GenderEnum.OTHER]: "Outro",
    },
};
