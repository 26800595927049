import { createStore } from "vuex";

import themeModule from "./modules/theme";
import userModule from "./modules/user";
import messageModule from "./modules/message";

const store = createStore({
  modules: {
    themeModule,
    userModule,
    messageModule,
  },
});

export default store;

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $store: typeof store
  }
}
