<script setup lang="ts">
import { ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";

interface OptionType {
  id: string;
  option: string;
  value: boolean;
}

const emit = defineEmits(["update:modelValue", "change"]);

const props = withDefaults(
  defineProps<{
    id: string;
    options: OptionType[];
    width?: string;
    value?: OptionType[];
  }>(),
  {
    width: "100%",
  }
);

const internalValue = ref<OptionType[]>([]);

function update() {
  emit("change", internalValue.value);
}

function fetchValue() {
  if (props.value) {
    internalValue.value = props.options.map((item) => {
      const matchedOption = props.value.find((option) => option.id === item.id);
      return { ...item, value: matchedOption ? matchedOption.value : false };
    });
  } else {
    internalValue.value = props.options.map((option) => ({
      ...option,
      value: false,
    }));
  }
}

watchEffect(() => {
  if (props.options) fetchValue();
});
</script>

<template>
  <div class="base-radio">
    <div v-if="$slots.label" class="label">
      <slot name="label" />
    </div>
    <div class="options">
      <template v-for="(item, index) in internalValue" :key="index">
        <div class="option">
          <input
            type="checkbox"
            v-model="item.value"
            :name="id"
            :id="`${id}${index}`"
            @change="update()"
          />
          <label :for="`${id}${index}`">{{ item.option }}</label>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$width: v-bind("width");

.base-radio {
  display: flex;
  flex-direction: column;
  width: $width;
  gap: 0.25rem;

  .label {
    font-size: 0.875rem;
    margin-block-end: 0.2rem;
    // margin-inline: 0.5rem;
  }

  .options {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .option {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.25rem;
      margin-inline: 0.25rem;

      input {
        width: 1rem;
        height: 0.9rem;
      }

      label {
        height: 1rem;
        font-size: 0.875rem;
      }
    }
  }
}
</style>
