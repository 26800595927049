<script setup lang="ts">
import store from "@/store";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import {
  svgIcon,
  mobileHeader,
  footerMenu,
  mobileMenu,
  fullLoading,
} from "@/common/components";

interface MenuListType {
  icon: string;
  label?: string;
  url?: string;
  key: string;
  active?: boolean;
}

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    accountId: string;
    tabActive: string;
    menuList: MenuListType[];
    showHome?: boolean;
    showBack?: boolean;
    backUrl?: string;
  }>(),
  {
    showHome: true,
    showBack: false,
  }
);

const headerHeight = "4rem";
const titleHeight = "4rem";
const footerHeight = "4rem";

const mobileMenuOpen = ref<string | null>(null);

const footerMenuList = computed(() => {
  const list: MenuListType[] = [
    {
      key: "menu",
      icon: "grid",
      label: t("common.labels.menu"),
    },
    {
      key: "alert",
      icon: "alert",
      label: t("common.labels.alert"),
    },
  ];

  if (props.showHome && !props.showBack)
    list.unshift({
      key: "home",
      icon: "home-line",
      label: t("common.labels.home"),
      url: `/${props.accountId}/home`,
    });

  if (!props.showHome && props.showBack)
    list.unshift({
      key: "back",
      icon: "arrows-left",
      label: t("common.labels.back"),
      url: props.backUrl,
      active: false,
    });

  return list;
});

function openFooterMenu(value: any) {
  mobileMenuOpen.value = value.key;
}

function menuMobileClose() {
  mobileMenuOpen.value = null;
}
</script>

<template>
  <section class="screen">
    <section class="pages">
      <mobile-header :height="headerHeight" />
      <main class="main">
        <section class="page-header">
          <slot name="title"></slot>
        </section>
        <section class="page-content">
          <slot name="content"></slot>
        </section>
      </main>
      <footer-menu
        :menu-list="footerMenuList"
        :height="footerHeight"
        @open="openFooterMenu"
      />
    </section>

    <template v-if="store.getters.loading">
      <full-loading />
    </template>

    <template v-if="mobileMenuOpen === 'menu'">
      <mobile-menu
        :menu-list="menuList"
        :active="tabActive"
        @close="menuMobileClose()"
      />
    </template>
  </section>
</template>

<style lang="scss" scoped>
$header-height: v-bind("headerHeight");
$title-height: v-bind("titleHeight");
$footer-height: v-bind("footerHeight");

.screen {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  .pages {
    display: flex;
    flex-direction: column;

    .page-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.75rem;
      height: $title-height;
      min-height: $title-height;
      background: var(--white-off);
      padding-inline: 1rem;
      color: var(--text);
      text-transform: uppercase;
      font-weight: 500;
    }

    .page-content {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      margin-block-end: 1rem;
    }

    main {
      display: flex;
      flex-direction: column;
      background: var(--white);
      // padding-block-start: 1rem;
      // padding-inline: 1rem;
      height: calc(100vh - $header-height - $footer-height);
      overflow-y: auto;
    }
  }
}
</style>