<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import { WebHeader, WebFooter } from "@/components";
import { svgIcon } from "@/common/components";

import { website } from "@/common/settings/website";

const { t } = useI18n();

const menuList = computed(() =>
  website.menu.map((item) => ({
    ...item,
    label: t(item.label),
  }))
);
</script>

<template>
  <article class="crew-page">
    <web-header :menu-list="menuList" menu-active="jobs" />

    <section class="header">
      <div class="title">
        <div class="icon">
          <svg-icon icon="bag" size="2.5rem" />
        </div>
        <span>
          {{ t("crew.labels.jobs") }}
        </span>
      </div>
      <div class="actions">
        <a href="/auth/login" role="button" class="btn btn-black h-10 px-8">
          {{ t("crew.labels.register_vacancy") }}
        </a>
      </div>
    </section>

    <section class="content"></section>

    <web-footer
      menu-active="jobs"
      :menu-list="menuList"
      :media-list="website.social_media"
      :copyright-year="website.copyright.year"
      :copyright-title="website.copyright.name"
    />
  </article>
</template>

<style lang="scss" scoped>
.crew-page {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 5rem;
    padding-inline: 4%;

    .title {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .icon {
        color: var(--secondary);
      }

      span {
        font-size: 1.5rem;
        text-transform: uppercase;
      }
    }

    .actions {
      display: flex;
      align-items: center;

      a {
        text-decoration: none;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    min-height: 53.4vh;
    gap: 1.54rem;
    background: #ffffff;
    padding-block: 1rem;
    padding-inline: 4%;
  }
}
</style>
