<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import moment from "moment";

import { svgIcon } from "@/common/components";

const { t } = useI18n();

defineProps<{
  mediaList: { key: string; label: string; url: string; icon: string }[];
  menuList: { key: string; label: string; url: string }[];
  menuActive: string;
  copyrightTitle: string;
  copyrightYear: string;
}>();

const yearNow = ref(moment().format("YYYY"));
</script>

<template>
  <footer class="web-footer">
    <section class="media">
      <template v-for="menu in mediaList" :key="menu.key">
        <router-link :to="menu.url" :title="menu.label">
          <svg-icon :icon="menu.icon" size="2rem" />
        </router-link>
      </template>
    </section>

    <section class="footer-menu">
      <nav class="menu">
        <template v-for="(menu, index) in menuList" :key="menu.key">
          <router-link
            :to="menu.url"
            :class="{
              active: menu.key === menuActive,
            }"
          >
            {{ menu.label }}
          </router-link>
          <template v-if="index + 1 < menuList.length">
            <label class="rounded" />
          </template>
        </template>
      </nav>

      <div class="copyright">
        <span class="simbol">©</span>
        <template v-if="copyrightYear && yearNow !== copyrightYear">
          <span> {{ copyrightYear }} - {{ yearNow }} </span>
        </template>
        <template v-else>
          <span>
            {{ yearNow }}
          </span>
        </template>
        <span>{{ copyrightTitle }}</span>
      </div>
    </section>

    <section class="brand">
      <a href="#">
        <img
          :src="require('@/assets/images/logo-secondary-full-white.png')"
          alt="Logo"
        />
      </a>
    </section>
  </footer>
</template>

<style lang="scss" scoped>
.web-footer {
  display: grid;
  grid-template-columns: auto 1fr 17rem;
  gap: 3rem;
  width: 100%;
  height: 8rem;
  background: var(--black-background);
  padding-inline: 4%;

  .media {
    display: inline-flex;
    align-items: center;
    gap: 1.5rem;

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 0.5rem;
      color: #f5f5f5;
      border: 1px solid #f5f5f5;

      &:hover {
        border: 2px solid var(--primary);
        color: var(--primary);
      }
    }
  }

  .footer-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;

    .menu {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1.5rem;

      a {
        display: flex;
        align-items: center;
        height: inherit;
        color: var(--secondary-color);
        text-decoration: none;
        text-transform: uppercase;
        font-weight: normal;
        font-size: 0.875rem;

        &.active {
          color: var(--primary);
        }

        &:hover {
          color: var(--primary);
        }
      }

      .rounded {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: var(--secondary-color);
      }
    }

    .copyright {
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 0.875rem;
      color: var(--secondary-color);

      .simbol {
        font-size: 1.25rem;
      }
    }
  }

  .brand {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: inherit;

    img {
      width: 100%;
    }
  }
}
</style>