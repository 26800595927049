<script setup lang="ts">
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { svgIcon, baseTooltip } from "@/common/components";

interface TabType {
  icon: string;
  url?: string;
  label?: string;
  key?: string;
}

const router = useRouter();

const emit = defineEmits(["select:tab"]);

const props = withDefaults(
  defineProps<{
    tabs: TabType[];
    active: string | null;
  }>(),
  {
    active: null,
  }
);

const tabActive = computed(() =>
  props.tabs.findIndex((item) => item.key === props.active)
);

function selectTab(data: TabType) {
  if (data.url) router.push(data.url);

  if (data.key) emit("select:tab", data.key);
}
</script>

<template>
  <section class="base-tabs">
    <div class="header">
      <template v-for="(tab, index) in tabs" :key="index">
        <base-tooltip>
          <template #trigger>
            <div
              class="tab"
              :class="{ active: tabActive === index }"
              @click="selectTab(tab)"
            >
              <div class="icon">
                <svg-icon :icon="tab.icon" size="2rem" />
              </div>
            </div>
          </template>
          <template v-if="tab.label" #tooltip>
            <div class="label">
              {{ tab.label }}
            </div>
          </template>
        </base-tooltip>
      </template>
    </div>
    <div v-if="$slots.title" class="title">
      <slot name="title"></slot>
    </div>
    <div class="content">
      <slot name="content"></slot>
    </div>
  </section>
</template>

<style lang="scss" scoped>
$height: 3.5rem;

.base-tabs {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;

    .tab {
      user-select: none;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 4.5rem;
      height: $height;
      background: var(--white-off);
      color: var(--text-off);
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;

      &.active,
      &:hover {
        color: var(--primary);
        background: var(--white);
      }

      .icon {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .label {
        font-size: 0.9rem;
      }
    }
  }

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.75rem;
    padding-inline: 1.5rem;
    padding-block: 0.75rem;
    text-transform: uppercase;
    font-size: 1.15rem;
    font-weight: bold;
    background: var(--white);
    border-block-end: 1px solid var(--border);
  }

  .content {
    display: flex;
    flex-direction: column;
    background: var(--white);
    padding-block: 1.5rem;
    padding-inline: 1.5rem;
  }
}
</style>