export const website = {
    social_media: [
        {
            key: "youtube",
            icon: "youtube",
            label: "Youtube",
            url: "#",
        },
        {
            key: "instagram",
            icon: "instagram",
            label: "Instagram",
            url: "#",
        },
    ],
    menu: [
        {
            key: "find_crew",
            label: "crew.labels.find_crew",
            url: "/public/crew",
        },
        {
            key: "jobs",
            label: "crew.labels.jobs",
            url: "/public/jobs",
        },
        {
            key: "services",
            label: "common.labels.services",
            url: "/public/services",
        },
        {
            key: "blog",
            label: "common.labels.blog",
            url: "#",
        },
        {
            key: "contact",
            label: "common.labels.contact",
            url: "#",
        },
    ],
    copyright: {
        year: "2024",
        name: "Brazilis Marina Luxury Yatch Group",
    }
};

export const socialMenu = [
    {
        key: "youtube",
        icon: "youtube",
        label: "Youtube",
        url: "#",
    },
    {
        key: "instagram",
        icon: "instagram",
        label: "Instagram",
        url: "#",
    },
];

export const siteMenu = [
    {
        key: "find_crew",
        label: "crew.labels.find_crew",
        url: "/public/crew",
    },
    {
        key: "jobs",
        label: "crew.labels.jobs",
        url: "/public/jobs",
    },
    {
        key: "services",
        label: "common.labels.services",
        url: "/public/services",
    },
    {
        key: "blog",
        label: "common.labels.blog",
        url: "#",
    },
    {
        key: "contact",
        label: "common.labels.contact",
        url: "#",
    },
];