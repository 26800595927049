export const home = {
    labels: {
        welcome: "Welcome",
        crew_member: "Crew Member",
        yacht_master: "Yacht Master",
    },
    texts: {
        crew_member: "We developed this exclusive space to facilitate and elevate the lives of Crew Members, newcomers and veterans, interested in entering and growing in the luxury superyacht industry. Here you will find job opportunities, professional courses, informative content and much more in just a few clicks. Check it out now!"
    },
};
