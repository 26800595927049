<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { DesktopPanelTheme, MobilePanelTheme } from ".";
import store from "@/store";

const { t } = useI18n();
const route = useRoute();

const accountId = computed(() => route.params.id as string);

const menuList = computed(() => {
  const list = [
    {
      key: "home",
      icon: "home-line",
      label: t("common.labels.home"),
      url: `/home`,
    },
    {
      key: "rudder",
      icon: "rudder",
      label: t("home.labels.crew_member"),
      url: `/crew`,
    },
    {
      key: "yacht",
      icon: "yacht",
      label: t("home.labels.yacht_master"),
      url: `/yacht`,
    },
    {
      key: "vacancies",
      icon: "bag",
      label: t("common.labels.vacancies"),
      url: `/vacancies`,
    },
    {
      key: "services",
      icon: "services",
      label: t("common.labels.services"),
      url: `/services`,
    },
    {
      key: "plans",
      icon: "plans",
      label: t("common.labels.plans"),
      url: "/plans",
    },
  ];

  return list;
});
</script>

<template>
  <template v-if="!$store.getters.mobile">
    <desktop-panel-theme :menu-list="menuList">
      <template #main>
        <slot></slot>
      </template>
    </desktop-panel-theme>
  </template>
  <template v-else>
    <mobile-panel-theme :menu-list="menuList" :account-id="accountId">
      <template #main>
        <slot></slot>
      </template>
    </mobile-panel-theme>
  </template>
</template>