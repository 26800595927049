import clientAxios from "../client";

import type {
    UserLoginType,
    UserRecoverType,
    UserRegisterType
} from "@/common/types/user";

export async function userLogin(
    payload: UserLoginType
) {
    return await clientAxios.post(
        `member/login`,
        payload
    );
}

export async function userRegister(
    payload: UserRegisterType
) {
    return await clientAxios.post(
        `member/register`,
        payload
    );
}

export async function userRecover(
    payload: UserRecoverType
) {
    return await clientAxios.post(
        `member/recover`,
        payload
    );
}

export async function userRecoverValid(
    payload: UserRecoverType
) {
    return await clientAxios.post(
        `member/recover/valid`,
        payload
    );
}

export async function userRecoverPassword(
    payload: UserRecoverType
) {
    return await clientAxios.post(
        `member/recover/password`,
        payload
    );
}

export async function userRecoverCheckEmail() {
    return await clientAxios.get(
        `member/recover/check/email`
    );
}

export async function userProfile(
    member_id: string
) {
    return await clientAxios.get(
        `member/${member_id}/profile`
    );
}

export async function userAccount(
    member_id: string
) {
    return await clientAxios.get(
        `member/${member_id}/account`
    );
}

export async function userPatchAccount(
    account_id: string,
    payload: {
        name: string,
        description: string | null
    }
) {
    return await clientAxios.patch(
        `member/account/${account_id}`,
        { payload }
    );
}

export async function userInvoices(
    member_id: string
) {
    return await clientAxios.get(
        `member/invoices/${member_id}`
    );
}