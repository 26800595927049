<script setup lang="ts">
import store from "@/store";
import chunk from "lodash-es/chunk";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import {
  mobileHeader,
  footerMenu,
  mobileMenu,
  fullLoading,
} from "@/common/components";

interface MenuListType {
  icon: string;
  label?: string;
  url?: string;
  key: string;
  active?: boolean;
}

const { t } = useI18n();

const props = defineProps<{
  accountId: string;
  tabActive: string;
  menuList: MenuListType[];
}>();

const headerHeight = "4rem";
const footerHeight = "4.5rem";

const mobileMenuOpen = ref<string | null>(null);

const footerMenuList = computed(() => [
  ...chunk(props.menuList || [], 3)[0],
  {
    key: "menu",
    icon: "grid",
    label: t("common.labels.menu"),
  },
]);

function openFooterMenu(value: any) {
  mobileMenuOpen.value = value.key;
  console.log("openFooterMenu", value);
}

function menuMobileClose() {
  mobileMenuOpen.value = null;
}
</script>

<template>
  <section class="screen">
    <section class="pages">
      <mobile-header :height="headerHeight" background="#1C4736" />
      <main class="main">
        <slot name="main"></slot>
      </main>
      <footer-menu
        background="#1C4736"
        :menu-list="footerMenuList"
        :height="footerHeight"
        @open="openFooterMenu"
      />
    </section>

    <template v-if="store.getters.loading">
      <full-loading />
    </template>

    <template v-if="mobileMenuOpen === 'menu'">
      <mobile-menu
        :menu-list="menuList"
        :active="tabActive"
        @close="menuMobileClose()"
      />
    </template>
  </section>
</template>

<style lang="scss" scoped>
$header-height: v-bind("headerHeight");
$footer-height: v-bind("footerHeight");

.screen {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  .pages {
    display: flex;
    flex-direction: column;

    main {
      display: flex;
      flex-direction: column;
      padding-block-start: 1rem;
      padding-inline: 1rem;
      height: calc(100vh - $header-height - $footer-height - 1rem);
      overflow-y: auto;
    }
  }
}
</style>