<script setup lang="ts">
import store from "@/store";
import { computed, onMounted, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute, onBeforeRouteLeave } from "vue-router";
import { MD5 } from "crypto-js";
import { toast } from "@/common/utils/toast";
import { maskNumber } from "@/common/utils/mask";

import { baseInput, baseCode, svgIcon } from "@/common/components";

import {
  userRecoverValid,
  userRecoverPassword,
  userRecoverCheckEmail,
} from "@/common/api/rest/user";

import type { UserRecoverType } from "@/common/types/user";

const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const hash = computed(() => {
  try {
    const decode = window.atob(String(route.query.hash));
    return JSON.parse(decode);
  } catch (_) {
    return null;
  }
});

const hashEmail = computed(() => hash.value?.email);
const hashCode = computed(() => hash.value?.code);

const payload = ref<UserRecoverType>({
  code: "",
  email: "",
  password: "",
});

const confirmPassword = ref("");

const error = ref<{ [key: string]: string }>({});
const step = ref(1);

const interval = ref();
const passwordShow = ref(false);
const ConfirmPasswordShow = ref(false);

function isCodeValid() {
  const { code, email, password } = payload.value;

  error.value = {};

  const codeLength = code?.trim().length || 0;
  if (codeLength === 0) error.value.code = t("error.required");
  if (codeLength > 0 && codeLength < 5)
    error.value.code = t("error.min", { min: 5 });

  return Object.keys(error.value).length === 0;
}

function isPasswordValid() {
  const { password } = payload.value;

  error.value = {};

  if (password?.trim().length === 0) error.value.password = t("error.required");

  if (confirmPassword.value.trim().length === 0)
    error.value.confirm = t("error.required");

  if (password !== confirmPassword.value)
    error.value.confirm = t("error.not_equal");

  return Object.keys(error.value).length === 0;
}

function back() {
  router.push("/auth/recover");
}

function nextStep() {
  router.push({
    path: "/auth/recover/valid",
    query: {
      hash: window.btoa(
        JSON.stringify({
          email: payload.value.email,
          code: payload.value.code,
        })
      ),
    },
  });
}

function submitCode() {
  if (!isCodeValid()) return;

  userRecoverValid(payload.value)
    .then((response) => {
      const { success, data } = response;
      if (success) {
        toast({ message: data.message, type: "success" });
        nextStep();
      } else {
        toast({ message: data.message, type: "error" });
      }
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    });
}

function submitPassword() {
  if (!isPasswordValid() || !payload.value.password) return;

  userRecoverPassword({
    ...payload.value,
    password: MD5(payload.value.password).toString(),
  })
    .then((response) => {
      const { success, data } = response;
      if (success) {
        toast({ message: data.message, type: "success" });
        router.push("/auth/login");
      } else {
        toast({ message: data.message, type: "error" });
      }
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    });
}

watchEffect(() => {
  if (hashEmail.value) payload.value.email = hashEmail.value;

  if (hashCode.value) {
    payload.value.code = hashCode.value;
    step.value = 2;
    clearTimeout(interval.value);
  }
});

onBeforeRouteLeave(() => {
  clearTimeout(interval.value);
});

onMounted(() => {
  interval.value = setInterval(async () => {
    await userRecoverCheckEmail();
  }, 25000);
});
</script>

<template>
  <main class="screen">
    <section class="box-login shadow">
      <div class="left">
        <img :src="require('@/assets/images/logo.png')" />
      </div>

      <div class="right">
        <template v-if="step === 1">
          <h2>
            {{ t("common.labels.code").toUpperCase() }}
          </h2>
          <div class="subtitle">
            {{ t("common.texts.input_code") }}
          </div>

          <form @submit.prevent="submitCode()">
            <base-code
              v-model="payload.code"
              placeholder="00000"
              background="#f1f1f1"
              minLength="5"
              maxLength="5"
              :mask="maskNumber"
              @update:modelValue="error.code = ''"
            >
              <template v-if="error.code" #error>
                {{ error.code }}
              </template>
            </base-code>

            <div class="actions">
              <button class="send btn btn-primary">
                {{ t("common.labels.send")?.toUpperCase() }}
              </button>

              <button
                type="button"
                class="back btn btn-sm btn-default"
                @click="back()"
              >
                {{ t("common.labels.back")?.toUpperCase() }}
              </button>
            </div>
          </form>
        </template>

        <template v-if="step === 2">
          <h2>
            {{ t("common.labels.new_password").toUpperCase() }}
          </h2>
          <form @submit.prevent="submitPassword()">
            <base-input
              v-model="payload.password"
              :placeholder="t('common.placeholders.password')"
              :type="passwordShow ? 'text' : 'password'"
              background="#f1f1f1"
              maxLength="50"
            >
              <template #label>
                {{ t("common.labels.password") }}
              </template>
              <template #sufix>
                <div
                  v-if="passwordShow"
                  class="pointer"
                  @click="passwordShow = false"
                >
                  <svg-icon icon="eye" size="1.5rem" />
                </div>
                <div v-else class="pointer" @click="passwordShow = true">
                  <svg-icon icon="eye-closed" size="1.5rem" />
                </div>
              </template>
              <template v-if="error.password" #error>
                {{ error.password }}
              </template>
            </base-input>

            <base-input
              v-model="confirmPassword"
              :placeholder="t('common.placeholders.password')"
              :type="ConfirmPasswordShow ? 'text' : 'password'"
              background="#f1f1f1"
              maxLength="50"
            >
              <template #label>
                {{ t("common.labels.confirm_password") }}
              </template>
              <template #sufix>
                <div
                  v-if="ConfirmPasswordShow"
                  class="pointer"
                  @click="ConfirmPasswordShow = false"
                >
                  <svg-icon icon="eye" size="1.5rem" />
                </div>
                <div v-else class="pointer" @click="ConfirmPasswordShow = true">
                  <svg-icon icon="eye-closed" size="1.5rem" />
                </div>
              </template>
              <template v-if="error.confirm" #error>
                {{ error.confirm }}
              </template>
            </base-input>

            <div class="actions">
              <button class="send btn btn-primary">
                {{ t("common.labels.save")?.toUpperCase() }}
              </button>

              <button
                type="button"
                class="back btn btn-sm btn-default"
                @click="back()"
              >
                {{ t("common.labels.back")?.toUpperCase() }}
              </button>
            </div>
          </form>
        </template>
      </div>
    </section>
  </main>
</template>

<style lang="scss" scoped>
.screen {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: var(--black-background);
  background-image: url("@/assets/images/sea-background.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .box-login {
    display: flex;
    flex-direction: row;
    width: 750px;
    height: 500px;
    background: var(--white);
    border-radius: 0.75rem;

    .left {
      display: flex;
      width: 50%;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 220px;
      }
    }

    .right {
      display: flex;
      width: 50%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      padding-inline: 8%;

      .subtitle {
        font-size: 0.875rem;
        text-align: center;
        padding-block-end: 0.5rem;
      }

      form {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 0.875rem;
      }

      .actions {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        margin-block-start: 1rem;
        gap: 2rem;

        .send {
          width: 100%;
        }

        .back {
          width: 6rem;
          height: 2rem;
          font-size: 0.875rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .screen {
    .box-login {
      flex-direction: column;
      width: 90%;
      height: auto;
      padding-block: 1.5rem;

      .left {
        width: 100%;
        padding-block: 1.5rem;
      }

      .right {
        width: 84%;
      }
    }
  }
}
</style>