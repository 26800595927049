<script setup lang="ts">
import { ref, watchEffect } from "vue";
import axios from "axios";

const props = withDefaults(
  defineProps<{
    icon: string;
    size?: string;
    path?: string;
    color?: string;
  }>(),
  {
    path: "icons",
    size: "30px",
    color: "currentColor",
  }
);

const svgImage = ref();

async function created() {
  if (!props.icon) return;

  const url = require(`@/assets/${props.path}/${props.icon}.svg`);

  const response = await axios.get(url);

  if (!response.data) return;

  const data = response.data;

  svgImage.value = data;
}

watchEffect(() => {
  created();
});
</script>

<template>
  <div v-if="svgImage" v-html="svgImage" class="svgicon" />
</template>

<style lang="scss" scoped>
.svgicon {
  display: flex;
  flex-direction: column;
  width: v-bind("size");
  height: v-bind("size");
  color: v-bind("color");
  align-items: center;
  justify-content: center;
}
</style>
