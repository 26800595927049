<script setup lang="ts">
import { ref, watchEffect } from "vue";

const props = withDefaults(
  defineProps<{
    width?: string;
    height?: string;
    radius?: string;
    background?: string;
    value?: string | number | null;
    mask?: (value: string) => void;
  }>(),
  {
    width: "100%",
    height: "100px",
    radius: "6px",
    background: "#fff",
  }
);

const emit = defineEmits(["update:modelValue"]);

const internalValue = ref<string | number | null>(null);

function update(event: any) {
  let value = event.target.value;
  if (props.mask) value = props.mask(value);

  internalValue.value = value;
  emit("update:modelValue", internalValue.value);
}

watchEffect(() => {
  console.log("props.value", props.value)
  if (props.value !== undefined) internalValue.value = props.value;
});
</script>

<template>
  <div class="base-textarea">
    <div v-if="$slots.label" class="label">
      <slot name="label" />
    </div>
    <div class="content" :class="{ error: $slots.error }">
      <div class="textarea">
        <textarea
          v-bind="$attrs"
          v-model="internalValue"
          autocomplete="off"
          @input="update"
        />
      </div>
    </div>
    <div v-if="$slots.error" class="error-message">
      <slot name="error" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
$width: v-bind("width");
$height: v-bind("height");
$radius: v-bind("radius");
$background: v-bind("background");

.base-textarea {
  display: flex;
  flex-direction: column;
  width: $width;
  gap: 2px;

  .label {
    font-size: 0.875rem;
    margin-block: 0.2rem;
    margin-inline: 0.5rem;
  }

  .content {
    display: flex;
    flex-direction: row;
    width: calc(100% - (1px * 2));
    height: calc($height + 4px);
    justify-content: center;
    align-items: center;
    border: 1px solid #d9d9d9;
    border-radius: $radius;
    background: $background;

    &.error {
      border-color: red;
    }
  }

  .error-message {
    font-size: 0.875rem;
    padding-inline-start: $radius;
    color: red;
  }

  .textarea {
    flex: 2;
    padding-inline: 5px;

    textarea {
      width: calc(100% - (10px * 2));
      height: calc($height - 4px - (7px * 2));
      padding-inline: 10px;
      padding-block: 7px;
      outline: none;
      font-size: 1rem;
      border: 0;
      resize: none;
      background: transparent;
      font-family: "Open Sans";
    }
  }
}
</style>