<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import store from "@/store";

import { PanelTheme } from "@/common/themes";
import { svgIcon } from "@/common/components";

const { t } = useI18n();
const router = useRouter();

const servicesItems = computed(() => [
  {
    key: "crew",
    icon: {
      icon: "rudder",
      size: "2.5rem",
    },
    title: t("home.labels.crew_member"),
    description: t("home.texts.crew_member"),
    button: {
      text: t("home.labels.create_profile"),
      url: "/crew",
    },
  },
  {
    key: "yacht",
    icon: {
      icon: "yacht",
      size: "3.5rem",
    },
    title: t("home.labels.yacht_master"),
    description: t("home.texts.yacht_master"),
    button: {
      text: t("home.labels.offer_yacht"),
      url: "/yacht",
    },
  },
  {
    key: "vacancies",
    icon: {
      icon: "bag",
      size: "2.5rem",
    },
    title: t("home.labels.vacancies"),
    description: t("home.texts.offer_vacancy"),
    button: {
      text: t("home.labels.offer_vacancy"),
      url: "/vacancies",
    },
  },
  {
    key: "services",
    icon: {
      icon: "services",
      size: "2.5rem",
    },
    title: t("home.labels.services"),
    description: t("home.texts.offer_vacancy"),
    button: {
      text: t("home.labels.see_services"),
      url: "/services",
    },
  },
]);

function goToUrl(url: string) {
  router.push(url);
}
</script>

<template>
  <panel-theme>
    <section class="home-page">
      <div class="card welcome">
        <span>{{ t("home.labels.welcome") }}&nbsp;</span>
        <template v-if="store.getters.profile?.social_name">
          <strong>
            {{ store.getters.profile?.social_name }}
          </strong>
        </template>
        <template v-else>
          <strong>
            {{ store.getters.profile?.first_name }}
            {{ store.getters.profile?.last_name }}
          </strong>
        </template>
      </div>

      <div class="services">
        <template v-for="service in servicesItems" :key="service.key">
          <div class="card service">
            <div class="title">
              <div class="icon">
                <svg-icon :icon="service.icon.icon" :size="service.icon.size" />
              </div>
              {{ service.title }}
            </div>
            <div class="description" v-html="service.description" />
            <div class="actions">
              <button
                type="button"
                class="btn btn-primary uppercase font-bold px-8"
                @click="goToUrl(service.button.url)"
              >
                {{ service.button.text }}
              </button>
            </div>
          </div>
        </template>
      </div>
    </section>
  </panel-theme>
</template>

<style lang="scss" scoped>
.home-page {
  display: flex;
  flex-direction: column;
  gap: 1.57rem;

  .services {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.57rem;
    padding-block-end: 2rem;

    .service {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
        text-transform: uppercase;
        font-size: 1.5rem;

        .icon {
          color: var(--secondary);
        }
      }

      .description {
        flex: 1;
      }

      .actions {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .home-page {
    .services {
      grid-template-columns: 1fr;
    }
  }
}
</style>
