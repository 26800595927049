<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();

defineProps<{
  menuList: { key: string; label: string; url: string }[];
  menuActive: string;
}>();
</script>

<template>
  <header class="web-header">
    <section class="brand">
      <img
        :src="require('@/assets/images/logo-secondary-white.png')"
        alt="Logo"
      />
    </section>
    <nav class="menu">
      <template v-for="menu in menuList" :key="menu.key">
        <router-link
          :to="menu.url"
          :class="{
            active: menu.key === menuActive,
          }"
        >
          {{ menu.label }}
        </router-link>
      </template>
    </nav>
    <section class="actions">
      <a
        href="/auth/login"
        role="button"
        class="btn btn-md btn-primary font-bold uppercase h-10 px-14"
      >
        {{ t("common.labels.login") }}
      </a>
    </section>
  </header>
</template>

<style lang="scss" scoped>
.web-header {
  display: grid;
  grid-template-columns: 22rem 1fr auto;
  gap: 1.5rem;
  width: 100%;
  height: 5.7rem;
  background: var(--secondary);
  padding-inline: 4%;

  .brand {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: inherit;

    img {
      height: 65%;
    }
  }

  .menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: center;
    gap: 1.5rem;

    a {
      display: flex;
      align-items: center;
      height: inherit;
      color: var(--secondary-color);
      text-decoration: none;
      text-transform: uppercase;
      font-weight: normal;
      font-size: 1rem;

      &.active {
        color: var(--primary);
      }

      &:hover {
        color: var(--primary);
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
    }
  }
}
</style>