<script setup lang="ts">
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";

import { FilterType } from "@/common/types/filter";

import {
  baseCheckbox,
  baseInput,
  baseSelect,
  baseMultiselect,
  svgIcon,
} from "@/common/components";

const { t, tm } = useI18n();

const emit = defineEmits(["filter:clear", "filter:apply"]);

interface AdvancedFilterType {
  id?: string;
  option: string;
  value: boolean;
}

const props = defineProps<{
  data: FilterType | null;
  showAdvancedFilters: boolean;
}>();

const DEFAULT_FILTER = {
  position: "",
  availability: [],
  experience: [],
  location: [],
  advanced_filters: [],
};

const filter = ref<FilterType>({ ...DEFAULT_FILTER });
const advancedFilter = ref<AdvancedFilterType[]>([]);

const availabilityOptions = computed(() =>
  Object.entries(tm("enums.availability")).map(([value, option]) => ({
    option,
    value,
  }))
);

const experienceOptions = computed(() =>
  Object.entries(tm("enums.experience")).map(([value, option]) => ({
    option,
    value,
  }))
);

const countriesOptions = computed(() =>
  Object.entries(tm("enums.countries")).map(([value, option]) => ({
    option,
    value,
  }))
);

const advancedFilterOptions = computed(() =>
  Object.entries(tm("enums.advanced_filter")).map(([value, option]) => ({
    id: value,
    option: option,
    value: false,
  }))
);

function updateAdvancedFilter(data: any) {
  filter.value.advanced_filters = [...data]
    .filter((item) => !!item.value)
    .map((item) => item.id);

  advancedFilter.value = Array.from(data);
}

function clearFilter() {
  filter.value = { ...DEFAULT_FILTER };
  advancedFilter.value = [];

  emit("filter:clear", filter.value);
}

function applyFilter() {
  emit("filter:apply", filter.value);
}
</script>

<template>
  <article class="crew-filter">
    <section class="header">
      <div class="icon">
        <svg-icon icon="filter" size="2.5rem" />
      </div>
      <span>
        {{ t("crew.texts.crew_filter_title") }}
      </span>
    </section>

    <section class="content">
      <div class="filter-base">
        <base-input
          v-model="filter.position"
          :value="filter.position"
          :placeholder="t('crew.placeholders.position')"
        >
          <template #label>
            {{ t("crew.labels.position") }}
          </template>
        </base-input>

        <base-multiselect
          v-model="filter.availability"
          :value="filter.availability"
          :options="availabilityOptions"
          :is-empty="false"
          :placeholder="t('crew.placeholders.availability')"
        >
          <template #label>
            {{ t("crew.labels.availability") }}
          </template>
        </base-multiselect>

        <base-multiselect
          v-model="filter.experience"
          :value="filter.experience"
          :options="experienceOptions"
          :is-empty="false"
          :placeholder="t('crew.placeholders.experience')"
        >
          <template #label>
            {{ t("crew.labels.experience") }}
          </template>
        </base-multiselect>

        <base-multiselect
          v-model="filter.location"
          :value="filter.location"
          :options="countriesOptions"
          :is-empty="false"
          :placeholder="t('crew.placeholders.location')"
        >
          <template #label>
            {{ t("crew.labels.location") }}
          </template>
        </base-multiselect>
      </div>

      <template v-if="showAdvancedFilters">
        <div class="filter-advanced">
          <base-checkbox
            :options="advancedFilterOptions"
            :value="advancedFilter"
            @change="updateAdvancedFilter($event)"
          >
            <template #label>
              {{ t("crew.labels.advanced_filters") }}
            </template>
          </base-checkbox>
        </div>
      </template>

      <div class="actions">
        <button
          type="button"
          class="btn btn-md btn-default flex-1 uppercase"
          @click="clearFilter()"
        >
          {{ t("crew.labels.clear_filter") }}
        </button>
        <button
          type="button"
          class="btn btn-md btn-primary flex-1 uppercase"
          @click="applyFilter()"
        >
          {{ t("crew.labels.apply_filter") }}
        </button>
      </div>
    </section>
  </article>
</template>

<style lang="scss" scoped>
.crew-filter {
  display: flex;
  flex-direction: column;
  width: 350px;
  gap: 1.5rem;

  .header {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 1rem;

    span {
      font-size: 0.875rem;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .filter-base {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
    }

    .filter-advanced {
      background: var(--white-off);
      padding: 1rem;
      margin-block-start: 0.5rem;
      border-radius: 0.25rem;
    }

    .actions {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      margin-block: 1rem;
    }
  }
}
</style>