<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { svgIcon } from "@/common/components";

interface MenuListType {
  icon: string;
  label?: string;
  url?: string;
  key: string;
}

const router = useRouter();

withDefaults(
  defineProps<{
    menuList: MenuListType[];
    active?: string;
    height?: string;
    background?: string;
    iconSize?: string;
  }>(),
  {
    iconSize: "1.75rem",
    height: "4rem",
    background: "#fff",
  }
);

const emit = defineEmits(["open", "close"]);

const show = ref(false);

function open(menu: MenuListType) {
  if (menu.url) {
    router.push(menu.url);
    return;
  }

  emit("open", menu);
}

function close() {
  show.value = false;
  setTimeout(() => {
    emit("close");
  }, 150);
}

onMounted(() => {
  setTimeout(() => {
    show.value = true;
  }, 150);
});
</script>

<template>
  <section class="mobile-menu">
    <transition name="fade-left">
      <div v-show="show" class="content">
        <div class="header">
          <div class="close">
            <button class="btn btn-sm btn-none" @click="close()">
              <svg-icon icon="close" size="1.5rem" />
            </button>
          </div>
        </div>
        <div class="menu">
          <template v-for="(menu, index) in menuList" :key="menu.key">
            <div
              class="item"
              :class="{
                active: active === menu.key,
              }"
              @click="open(menu)"
            >
              <div class="icon">
                <svg-icon :icon="menu.icon" :size="iconSize" />
              </div>
              <template v-if="menu.label">
                <span>{{ menu.label }}</span>
              </template>
            </div>
            <template v-if="(index + 1) < menuList?.length">
              <div class="separator" />
            </template>
          </template>
        </div>
      </div>
    </transition>
    <div class="off" @click="close()" />
  </section>
</template>

<style lang="scss" scoped>
.mobile-menu {
  position: fixed;
  z-index: 100;
  bottom: 0;
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  background: #00000096;

  .header {
    display: flex;
    flex-direction: column;

    .close {
      display: flex;
      justify-content: flex-end;
      padding: 0.5rem;

      button {
        color: var(--text-off);

        &:hover {
          color: var(--text);
        }
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    background: var(--white);
    width: 85%;
    height: 100%;

    .menu {
      display: flex;
      flex-direction: column;

      .item {
        user-select: none;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        padding-block: 0.5rem;
        padding-inline: 1.57rem;
        color: var(--text-off);

        &:hover {
          color: var(--secondary);
        }

        &.active {
          background: var(--secondary);
          color: var(--white);
        }
      }

      .separator {
        width: 100%;
        height: 2px;
        background: var(--white-off);
      }
    }
  }

  .off {
    width: 15%;
    height: 100%;
  }
}

.fade-left-enter-active {
  transition: opacity 1s ease, transform 1s ease;
}
.fade-left-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.fade-left-enter-to {
  opacity: 1;
  transform: translateX(0);
}
</style>