<script setup lang="ts">
import store from "@/store";
import { computed, onBeforeMount, onMounted, ref, watch } from "vue";
import { getUser, removeUser } from "@/common/services/user";
import { userProfile, userAccount } from "@/common/api/rest/user";
import { useRoute } from "vue-router";

const route = useRoute();

const isMobile = ref(false);
const accountId = computed(() => route.params.id as string);

function fecthAccount(member_id: string) {
  userAccount(member_id).then((response) => {
    const { success, data } = response;

    if (success) {
      store.commit("SET_ACCOUNT", data.owner);

      if (data.owner?.account_id === accountId.value) {
        store.commit("SET_TYPE", data.owner?.type);
        console.log("owner")
      }
      if (data.affiliate) {
        const [affiliate] = data.affiliate?.filter(
          (item: any) => item.account_id === accountId.value
        );

        if (affiliate) {
          console.log("affiliate")
          store.commit("SET_TYPE", affiliate?.type);
        }
      }
    } else {
      if (data.code === 401) removeUser();
    }
  });
}

function fecthProfile(member_id: string) {
  userProfile(member_id).then((response) => {
    const { success, data } = response;
    if (success) {
      store.commit("SET_PROFILE", data);
    } else {
      if (data.code === 401) removeUser();
    }
  });
}

onBeforeMount(() => {
  const user = getUser();
  if (!user) {
    removeUser();
    return;
  }

  fecthProfile(user.member_id);
  fecthAccount(user.member_id);
  store.commit("SET_USER", user);
  store.commit("SET_LOGGED", true);
});

watch(
  () => isMobile.value,
  () => {
    store.commit("SET_MOBILE", isMobile.value);
  }
);

onMounted(() => {
  isMobile.value = window.innerWidth < 1024;

  window.addEventListener("resize", () => {
    isMobile.value = window.innerWidth < 1024;
  });
});
</script>

<template>
  <router-view />
</template>
