export const common = {
    languages: {
        pt_br: "pt-br",
        en_us: "en-us",
    },
    labels: {
        account: "Conta",
        accounts: "Contas",
        active: "Ativo",
        action: "Ação",
        actions: "Ações",
        address: "Endereço",
        addresses: "Endereços",
        add: "Adicionar {concat}",
        administrator: "Administrador",
        alert: "Alerta",
        analyse: "Análise",
        analysis: "Análises",
        apply: "Aplicar",
        assistant: "Auxiliar",
        assistants: "Auxiliares",
        avatar: "Avatar",
        back: "Voltar",
        bigger: "Maior",
        birthdate: "Nascimento",
        blog: "Blog",
        brand: "Marca",
        calc: "Cálculo",
        calibration_date: "Data de Calibração",
        cancel: "Cancelar {concat}",
        canceled: "Cancelado",
        canceleds: "Cancelados",
        cep: "CEP",
        certificate: "Certificado",
        certificates: "Certificados",
        change_account: "Trocar Conta",
        city: "Cidade",
        clean: "Limpar",
        close: "Fechar",
        cnae: "CNAE",
        cnpj: "cnpj",
        cockpit: "Cockpit",
        code: "Código",
        company: "Empresa",
        companies: "Empresas",
        complement: "Complemento",
        condition: "Condição",
        conditions: "Condições",
        concluded: "Concluído",
        confirm: "Confirmar",
        confirm_password: "Confirmar senha",
        configs: "Configurações",
        continue: "Continuar",
        contact: "Contato",
        contacts: "Contatos",
        corporate_reason: "Razão Social",
        country: "País",
        cpf: "cpf",
        crew: "Equipe",
        deduct: "Subtrair",
        date: "Data",
        dates: "Datas",
        delete: "Excluir {concat}",
        description: "Descrição",
        details: "Detalhes",
        district: "Bairro",
        divide: "Dividir",
        document: "Documento",
        documents: "Documentos",
        document_type: "Tipo de documento",
        download: "Download",
        edit: "Editar {concat}",
        equal: "Igual",
        equipment: "Equipamento",
        equipments: "Equipamentos",
        email: "E-mail",
        employee: "Funcionário",
        employees: "Funcionários",
        enter: "Entrar",
        exit: "Sair",
        expiration: "Vencimento",
        expiration_date: "Data de Validade",
        evaluation: "Avaliação",
        evaluations: "Avaliações",
        fabrication: "Data de Fabricação",
        fantasy_name: "Nome Fantasia",
        field: "Campo",
        fields: "Campos",
        file: "Arquivo",
        files: "Arquivos",
        finish: "Finalizar",
        form: "Formulário",
        forms: "Formulários",
        footer: "Rodapé",
        group: "Grupo",
        groups: "Grupos",
        home: "Home",
        if: "Se",
        inactive: "Inativo",
        invoices: "Faturas",
        info: "Informação",
        infos: "Informações",
        item: "Item",
        items: "Itens",
        laboratory: "Laboratório",
        laboratories: "Laboratórios",
        login: "Login",
        manager: "Gestor",
        menu: "Menu",
        method: "Método",
        methods: "Métodos",
        method_group: "Grupo de Métodos",
        model: "Modelo",
        my_agenda: "Minha Agenda",
        my_account: "Minha Conta",
        my_profile: "Meu Perfil",
        name: "Nome",
        new: "Novo {concat}",
        new_password: "Nova senha",
        next: "Próximo",
        next_calibration: "Próxima Calibração",
        number: "Número",
        number_of_employee: "Número de funcionários",
        not: "Não",
        observation: "Observação",
        observations: "Observações",
        open: "Abrir",
        option: "Opção",
        options: "Opções",
        order: "Ordem",
        other: "Outro",
        page: "Página",
        pages: "Páginas",
        password: "Senha",
        pending: "Pendente",
        phone: "Telefone",
        plans: "Planos",
        play: "Iniciar {concat}",
        price: "Valor",
        print: "Imprimir",
        progress: "Progresso",
        question: "Pergunta",
        questions: "Perguntas",
        refresh: "Atualizar",
        registration: "Cadastro",
        registrations: "Cadastros",
        register: "Cadastrar",
        required: "Obrigatório",
        remove: "Remover {concat}",
        report: "Relatório",
        reports: "Relatórios",
        role: "Cargo",
        sample: "Amostra",
        samples: "Amostras",
        save: "Salvar",
        search: "Buscar",
        sector: "Setor",
        send: "Enviar",
        serial_number: "Número de Série",
        service: "Serviço",
        services: "Serviços",
        setting: "Configuração",
        settings: "Configurações",
        showing: "Mostrando",
        signature: "Assinatura",
        sign: "Assinar",
        smaller: "Menor",
        social_name: "Nome Social",
        start: "Início",
        started: "Iniciado",
        state: "Estado",
        status: "Status",
        stop: "Parar",
        subscriptions: "Assinaturas",
        subtitle: "Subtítulo",
        sum: "Somar",
        summary: "Sumário",
        technical: "Técnico",
        technicals: "Técnicos",
        termination: "Término",
        title: "Título",
        titles: "Títulos",
        time: "Tempo",
        total_duration: "Duração total",
        type: "Tipo",
        upload: "Upload {concat}",
        user: "Usuário",
        users: "Usuários",
        validity: "Validade",
        visible: "Visível",
        vacancy: "Vaga",
        vacancies: "Vagas",
        value: "Valor",
        yacht: "Iate",
        yachts: "Iates",
        yes: "Sim",
    },
    placeholders: {
        code: "Informe o código",
        describe: "Descreva",
        email: "Informe o e-mail",
        login: "Informe seu Login",
        name: "Informe o nome",
        order: "Informe a órdem",
        password: "Informe sua Senha",
        select: "Selecione...",
    },
    texts: {
        no_results: "Não há resultados para serem exibidos.",
        forgot_password: "Esqueceu sua Senha?",
        recover_password: "Recuperar Senha",
        already_account: "Já possui conta?",
        dont_account: "Não possui conta?",
        register_account: "Registrar conta",
        remembered_password: "Lembrou sua senha?",
        undo_message: "Essa operação não poderá ser desfeita!",
        item_message: "Os itens adicionados também serão perdidos!",
        remove_message: "Você deseja remover <strong>''{concat}''</strong>?<br/>Esta ação não poderá ser desfeita!",
        input_code: "Digite abaixo o código que foi enviado para o e-mail cadastrado:",
        file_upload: "Selecione ou arraste o arquivo para enviar",
        model_download: "Baixar arquivo o modelo",
    }
};