<script setup lang="ts">
import { computed, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import Dropdown from "primevue/dropdown";

const { t } = useI18n();

const emit = defineEmits(["update:modelValue", "change"]);

const props = withDefaults(
  defineProps<{
    options: { option: string; value: string | number | boolean }[];
    width?: string;
    height?: string;
    radius?: string;
    background?: string;
    placeholder?: string;
    value?: any;
    isEmpty?: boolean;
    loading?: boolean;
  }>(),
  {
    width: "100%",
    height: "2.75rem",
    radius: "6px",
    background: "#fff",
    isEmpty: true,
  }
);

const internalValue = ref<string | number | null>(null);

const internalOptions = computed(() => {
  const optionEmpty = {
    label: t("common.placeholders.select"),
    value: "empty",
  };

  const optionsFormatted = (props.options || []).map((item) => ({
    label: item.option,
    value: item.value,
  }));

  return props.isEmpty ? [optionEmpty, ...optionsFormatted] : optionsFormatted;
});

function update(data: { originalEvent: Event; value: any }) {
  internalValue.value = data.value === "empty" ? null : data.value;
  emit("update:modelValue", internalValue.value);
  emit("change", internalValue.value);
}

watchEffect(() => {
  if (props.value !== undefined) internalValue.value = props.value;
});
</script>

<template>
  <div class="base-select">
    <div v-if="$slots.label" class="label">
      <slot name="label" />
    </div>
    <div class="content" :class="{ error: $slots.error }">
      <Dropdown
        v-if="!loading"
        class="custom-dropdown"
        v-bind="$attrs"
        v-model="internalValue"
        :options="internalOptions"
        optionLabel="label"
        optionValue="value"
        :placeholder="
          placeholder ? placeholder : t('common.placeholders.select')
        "
        @change="update"
      >
        <template #option="slotProps">
          <div
            v-if="slotProps"
            class="options"
            :class="{ empty: slotProps.option.value === 'empty' }"
          >
            {{ slotProps.option.label }}
          </div>
        </template>
      </Dropdown>
    </div>
    <div v-if="$slots.error" class="error-message">
      <slot name="error" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
$width: v-bind("width");
$height: v-bind("height");
$radius: v-bind("radius");
$background: v-bind("background");

.base-select {
  display: flex;
  flex-direction: column;
  width: $width;
  gap: 2px;

  .label {
    font-size: 0.875rem;
    margin-block-end: 0.2rem;
    margin-inline: 0.5rem;
  }

  .content {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-radius: $radius;
    background: $background;

    &.error {
      border-color: red;
    }
  }

  .error-message {
    text-align: end;
    font-size: 0.8rem;
    padding-inline: $radius;
    color: red;
  }

  .custom-dropdown {
    position: relative;
    display: flex;
    align-items: center;
    height: $height;
  }
}

:deep(.p-dropdown) {
  width: calc(100% - 2px);
  border: none;
  border-radius: $radius;
  border: 1px solid #d9d9d9;
}

:deep(.p-placeholder) {
  outline: none;
  color: var(--text-medium);
}
</style>