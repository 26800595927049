export enum AvailabilityEnum {
    IMMEDIATELY = "IMMEDIATELY",
    WITHIN_1_WEEK = "WITHIN_1_WEEK",
    WEEKS_1_2 = "WEEKS_1_2",
    WEEKS_2_2 = "WEEKS_2_2",
    WEEKS_4_MORE = "WEEKS_4_MORE",
    UNKNOWN = "UNKNOWN",
    UNAVAILABLE = "UNAVAILABLE",
}

export enum ExperienceEnum {
    MONTHS_0_6 = "MONTHS_0_6",
    MONTHS_6_YEAR_1 = "MONTHS_6_YEAR_1",
    YEARS_1_2 = "YEARS_1_2",
    YEARS_2_5 = "YEARS_2_5",
    YEARS_5_MORE = "YEARS_5_MORE",
    YEARS_10_MORE = "YEARS_10_MORE",
}

export enum GenderEnum {
    MALE = "MALE",
    FEMALE = "FEMALE",
    OTHER = "OTHER",
}

export enum AdvancedFilterEnum {
    MALE = "MALE",
    FEMALE = "FEMALE",
    TEAM_COUPLE_ONLY = "TEAM_COUPLE_ONLY",
    SAILING = "SAILING",
    MOTOR = "MOTOR",
    COMMERCIAL = "COMMERCIAL",
    CHARTER = "CHARTER",
    B1_B2_VISA = "B1_B2_VISA",
    C1_D_VISA = "C1_D_VISA",
    GREEN_CARD_US_CITIZEN = "GREEN_CARD_US_CITIZEN",
    SCHENGEN_EU_RESIDENT = "SCHENGEN_EU_RESIDENT",
    HOSPITALITY_EXPERIENCE = "HOSPITALITY_EXPERIENCE",
    MICHELIN_EXPERIENCE = "MICHELIN_EXPERIENCE",
    INTRODUCTION_VIDEO = "INTRODUCTION_VIDEO",
    VERIFIED_REFERENCE = "VERIFIED_REFERENCE",
    BACKGROUND_CHECK = "BACKGROUND_CHECK",
    SEA_SERVICE_PROVIDED = "SEA_SERVICE_PROVIDED",
}

export enum CountryEnum {
    AFGHANISTAN = "AF",
    SOUTH_AFRICA = "ZA",
    GERMANY = "DE",
    ARGENTINA = "AR",
    AUSTRALIA = "AU",
    BELGIUM = "BE",
    BRAZIL = "BR",
    CANADA = "CA",
    CHILE = "CL",
    CHINA = "CN",
    COLOMBIA = "CO",
    SOUTH_KOREA = "KR",
    DENMARK = "DK",
    EGYPT = "EG",
    UNITED_ARAB_EMIRATES = "AE",
    SPAIN = "ES",
    UNITED_STATES = "US",
    FRANCE = "FR",
    GREECE = "GR",
    INDIA = "IN",
    INDONESIA = "ID",
    IRAN = "IR",
    ISRAEL = "IL",
    ITALY = "IT",
    JAPAN = "JP",
    MEXICO = "MX",
    NIGERIA = "NG",
    NORWAY = "NO",
    NEW_ZEALAND = "NZ",
    NETHERLANDS = "NL",
    PAKISTAN = "PK",
    PERU = "PE",
    POLAND = "PL",
    PORTUGAL = "PT",
    UNITED_KINGDOM = "GB",
    RUSSIA = "RU",
    SWEDEN = "SE",
    SWITZERLAND = "CH",
    THAILAND = "TH",
    TURKEY = "TR",
    UKRAINE = "UA",
    URUGUAY = "UY",
    VENEZUELA = "VE",
    VIETNAM = "VN",
    ZIMBABWE = "ZW"
}
